/* Counter */

.Counter {
  display: flex;
  justify-content: start;
  font-family: MuseoSlab;
  text-align: center;
  padding-top: 40px;
}

.time {
  font-size: 32px;
}

/*  */
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading .spinner {
  background-color: transparent;
  border: 2px solid grey;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-top-color: red;
  border-bottom-color: blue;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.counter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  padding: 0 20px;
}

.counter-container h1 {
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 3px;
}

.counter-container .countdown {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.counter-container article {
  margin: 10px;
  width: 125px;
  background: #000;
  padding: 10px;
  font-family: MuseoSlab;
  border-radius: 5px;
}

.counter-container article p {
  /* padding: 10px; */
  /* background-color: #fff; */
  /* border-radius: 50%; */
  width: 100%;
  margin-bottom: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 69px;
  font-weight: 700;
  color: white;
  font-family: MuseoSlab;
  line-height: 1.3em;
}

.counter-container article h3 {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700;
}
/* 
.counter-color {
  margin-top: 10vh;
} */
